import styles from "../styles/CheckIn.module.css"
import { Button, Card, Stack } from 'react-bootstrap';
import { CheckIn as CheckInModel } from "../models/checkin";

interface CheckInProps {
    checkInIndex: number,
    checkIn: CheckInModel,
    checkInDisabled: boolean,
    deleteDisabled: boolean,
    rowClickable: boolean,
    onCheckInRowClicked: (checkIn: CheckInModel) => void
    onCheckInButtonClicked: (checkIn: CheckInModel) => void
    onDeleteButtonClicked: (checkIn: CheckInModel) => void
}

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
};

const CheckIn = ({ checkInIndex, checkIn, checkInDisabled, deleteDisabled, rowClickable, onCheckInButtonClicked, onCheckInRowClicked, onDeleteButtonClicked }: CheckInProps) => {
    const formattedDate = formatDate(checkIn.createdAt);
    const handleClick = () => {
        if (rowClickable) {
            onCheckInRowClicked(checkIn);
        }
    };
    return (
        <Card
            className={styles.singleCheckInRow}
            onClick={handleClick} // Attach the handleClick function to onClick
            style={{ cursor: rowClickable ? 'pointer' : 'default' }}
        >
            <Stack direction="horizontal" gap={3}>
                <div className={`p-2 ${styles.checkInRowUserName}`}>{checkInIndex + '.'}</div>
                <div className="p-2">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className={styles.checkInRowUserName}>{checkIn.userName}</span>
                        <span className={styles.checkInRowServiceType}>{checkIn.serviceType}</span>
                    </div>
                </div>
                <div className="p-2 ms-auto">{checkIn.serviceProvider}</div>
                <div className="p-2">{formattedDate}</div>
                <div className="p-2">{checkIn.status}</div>
                <Stack direction="horizontal" gap={0}>
                    <div className="p-2">
                        {!checkInDisabled &&
                            <Button
                                disabled={checkInDisabled}
                                variant="success"
                                onClick={(e) => {
                                    onCheckInButtonClicked(checkIn);
                                    e.stopPropagation();
                                }}>
                                Check In
                            </Button>
                        }

                    </div>
                    <div className="p-2">
                        {!deleteDisabled &&
                            <Button
                                disabled={deleteDisabled}
                                variant="outline-success"
                                onClick={(e) => {
                                    onDeleteButtonClicked(checkIn);
                                    e.stopPropagation();
                                }}>
                                Delete
                            </Button>
                        }
                    </div>
                </Stack>
            </Stack>
        </Card>
    )
}

export default CheckIn;