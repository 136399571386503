import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { User } from "../models/user";
import NavBarLoggedInView from "./NavBarLoggedInView";
import NavBarLoggedOutView from "./NavBarLoggedOutView";
import { Link } from "react-router-dom";
import { SalonLocations } from "../constants/SalonLocations";

interface NavBarProps {
    loggedInUser: User | null,
    onSignUpClicked: () => void,
    onLogInClicked: () => void,
    onLogoutSuccessful: () => void,
    onSalonSelected: (salonLocation: string) => void,
}

const NavBar = ({ loggedInUser, onSignUpClicked, onLogInClicked, onLogoutSuccessful, onSalonSelected }: NavBarProps) => {

    const [choosenSalon, setChoosenSalon] = useState<string | null>(() => {
        // Get the initial salon location from localStorage, if available
        return localStorage.getItem('selectedSalon') || "Choose Salon";
    });

    return (
        <Navbar bg="primary" variant="dark" expand="md" sticky="top">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img
                        alt="Company logo"
                        src="/favicon.ico"
                        height="30"
                        className="d-inline-block align-top"
                        style={{ marginRight: '10px' }}
                    />{' '}
                    Perfect Threading
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="main-navbar" />
                <Navbar.Collapse id="main-navbar">
                    <Nav>
                        <Nav.Link as={Link} to="/privacy">
                            Privacy
                        </Nav.Link>
                    </Nav>
                    <Nav>
                        {loggedInUser && loggedInUser.isAdmin && (
                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                title={choosenSalon}
                                menuVariant="light"
                            >
                                {SalonLocations.map((location, index) => (
                                    <NavDropdown.Item
                                        key={index}
                                        onClick={() => {
                                            setChoosenSalon(location)
                                            onSalonSelected(location)
                                        }
                                        }
                                    >
                                        {location}
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                        )}
                    </Nav>
                    <Nav className="ms-auto">
                        {loggedInUser
                            ? <NavBarLoggedInView user={loggedInUser} onLogoutSuccessful={onLogoutSuccessful} />
                            : <NavBarLoggedOutView onSignUpClicked={onSignUpClicked} onLoginClicked={onLogInClicked} />
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar;