import { User } from "../models/user";
import { fetchData } from "./fetch_helper";

export async function getLoggedInUser(): Promise<User> {
    const response = await fetchData("/api/v1/users",
        {
            method: "GET"
        });
    return response.json();
}


export interface SignUpCredentials {
    fullName: string,
    email: string,
    password: string,
}

export async function signUp(credentials: SignUpCredentials): Promise<User> {
    const response = await fetchData("/api/v1/users/signup", {
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(credentials),
    });

    return response.json();
}

export interface LoginCredentials {
    email: string,
    password: string
}

export async function login(credentials: LoginCredentials): Promise<User> {
    const response = await fetchData("/api/v1/users/login", {
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(credentials),
    });

    return response.json();
}

export async function logout() {
    await fetchData("/api/v1/users/logout", { method: "POST" });
}
