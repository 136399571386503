import { Button, Card, Col, Container, Row } from "react-bootstrap";

interface CheckinsPageLoggedOutViewProps {
    onLoginClicked: () => void,
    onSignUpClicked: () => void,
}

const CheckinsPageLoggedOutView = ({ onLoginClicked, onSignUpClicked }: CheckinsPageLoggedOutViewProps) => {
    return (
        <>
            <Container>
                <Card className="mainPageCard">
                    <div className="centerElement">
                        <Card.Title>
                            <img
                                alt="Company logo"
                                src="/perfect-threading-one.png"
                                height="200"
                                className="d-inline-block align-top"
                            />
                        </Card.Title>
                        <Card.Body>
                            <h2 style={{ marginTop: '30px' }}>WELCOME</h2>
                            <h3>Please login or signup to continue.</h3>
                            <Row style={{ marginTop: '30px' }}>
                                <Col sm={6} className="mb-3"><Button variant="primary" onClick={onSignUpClicked} size={"lg"} style={{ width: '200px' }}>Sign Up</Button></Col>
                                <Col sm={6} className="mb-3"><Button variant="primary" onClick={onLoginClicked} size={"lg"} style={{ width: '200px' }}>Login</Button></Col>
                            </Row>
                        </Card.Body>
                    </div>
                </Card>
            </Container>
        </>
    );
}

export default CheckinsPageLoggedOutView;
