import React, { useState } from "react";
import { Modal, ModalFooter, Button, Form, Row, Col } from "react-bootstrap";
import { SignUpCredentials } from "../network/users_api"
import { useForm } from "react-hook-form";
import * as UsersApi from "../network/users_api"
import { User } from "../models/user";


interface SignUpDialogProps {
    onDismiss: () => void,
    onSignUpSuccessful: (user: User) => void
}

const SignupModal = ({ onDismiss, onSignUpSuccessful }: SignUpDialogProps) => {

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<SignUpCredentials>()

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
    };

    const handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFullName = event.target.value;
        setFullName(newFullName);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
    };

    async function onSubmit() {
        try {
            const input: SignUpCredentials = {
                fullName: fullName,
                email: email,
                password: password
            };
            const user = await UsersApi.signUp(input)
            onSignUpSuccessful(user);
        } catch (error) {
            console.log(error);
            alert(error);
        }
    }

    return (
        <Modal show onHide={onDismiss} centered backdrop="static">
            <Modal.Header className="px-4" closeButton>
                <Modal.Title className="ms-auto">
                    Sign Up
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="signUpForm" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridFullName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Full name"
                                isInvalid={!!errors.fullName}
                                {...register("fullName", { required: "Your name is required" })}
                                onChange={handleFullNameChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.fullName?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                isInvalid={!!errors.email}
                                {...register("email", { required: "Email address is required" })}
                                onChange={handleEmailChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                isInvalid={!!errors.password}
                                {...register("password", { required: "Password is required" })}
                                onChange={handlePasswordChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.password?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <ModalFooter>
                <p style={{fontSize: '0.8em', marginLeft: 'auto', marginRight: 'auto'}}>By signing up, you agree to our Privacy Policy and Terms of Service. Which can be accessed by clicking 'Privacy' at the top.</p>
                <Button variant="primary" type="submit" form="signUpForm" disabled={isSubmitting} className="w-100">
                    Sign Up
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SignupModal;