import { Button, Col, Modal, Row } from "react-bootstrap";


interface ConfirmationDialogProps {
    title: string,
    message: string,
    positiveButtonText: string,
    negativeButtonText: string,
    onDismiss: () => void,
    onPositiveButtonClick: () => void,
    onNegativeButtonClick: () => void
}


const ConfirmationDialog = ({ title, message, positiveButtonText, negativeButtonText, onDismiss, onPositiveButtonClick, onNegativeButtonClick }: ConfirmationDialogProps) => {
    return (
        <Modal show onHide={onDismiss} centered backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Row className="d-flex justify-content-between w-100">
                    <Col className="d-flex justify-content-between w-100">
                        <Button variant="secondary" onClick={onNegativeButtonClick}>
                            {negativeButtonText}
                        </Button>
                        <Button variant="primary" onClick={onPositiveButtonClick}>
                            {positiveButtonText}
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}


export default ConfirmationDialog;
