import { CheckIn } from "../models/checkin";
import { fetchData } from "./fetch_helper";

export async function fetchCheckins(): Promise<CheckIn[]> {
    const response = await fetchData("/api/v1/checkins", { method: "GET" });
    return response.json();
}


export interface CheckinInput {
    userName: string,
    salonLocation: string,
    serviceProvider: string,
    serviceType: string
}

export async function createCheckIn(checkIn: CheckinInput): Promise<CheckIn> {
    
    // Create a new object with the desired properties
    const modifiedCheckIn = {
        userName: checkIn.userName,
        salonLocation: checkIn.salonLocation,
        serviceProvider: checkIn.serviceProvider,
        serviceType: checkIn.serviceType,
    };

    const response = await fetchData("/api/v1/checkins", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(modifiedCheckIn)
    });

    return response.json();
}

export async function deleteCheckIn(checkInId: string) {
    await fetchData("/api/v1/checkins/" + checkInId, { method: "DELETE" });
}

export async function updateCheckIn(checkInId: string, checkIn: CheckinInput): Promise<CheckIn> {
    const response = await fetchData("/api/v1/checkins/" + checkInId, 
    {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(checkIn)
    });

    return response.json();
}

interface WaitTimeResponse {
    waitTimeInMinutes: number;
}

export async function getWaitTime(): Promise<WaitTimeResponse | null> {
    const response = await fetchData("/api/v1/checkins/waitTime",
        {
            method: "GET"
        });
    return response.json();
}