import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginModal from './components/LoginDialog';
import NavBar from './components/NavBar';
import SignupModal from './components/SignupDialog';
import { User } from './models/user';
import * as UsersApi from "./network/users_api";
import CheckInsPage from './pages/CheckInsPage';
import NotFoundPage from './pages/NotFoundPage';
import PrivacyPage from './pages/PrivacyPage';

function App() {

	const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
	const [shouldShowSignUpDialog, setShouldShowSignUpDialog] = useState(false);
	const [shouldShowLogInDialog, setShouldShowLogInDialog] = useState(false);
	const [selectedSalon, setSelectedSalon] = useState<string | null>(() => {
        // Get the initial salon location from localStorage, if available
        return localStorage.getItem('selectedSalon') || null;
    });

	useEffect(() => {
		async function fetchLoggedInUser() {
			try {
				const user = await UsersApi.getLoggedInUser()
				setLoggedInUser(user)
			} catch (error) {
				console.error(error);
			}
		}

		fetchLoggedInUser();
	}, []);

	// Save the selected salon location to localStorage whenever it changes
    useEffect(() => {
        if (selectedSalon) {
            localStorage.setItem('selectedSalon', selectedSalon);
        } else {
            localStorage.removeItem('selectedSalon');
        }
    }, [selectedSalon]);

	const handleSalonSelected = (salon: string) => {
        setSelectedSalon(salon);
        console.log("Selected Salon:", salon);
    };

	return (
		<BrowserRouter>
			<div>
				<NavBar
					loggedInUser={loggedInUser}
					onLogInClicked={() => { setShouldShowLogInDialog(true) }}
					onSignUpClicked={() => { setShouldShowSignUpDialog(true) }}
					onLogoutSuccessful={() => { setLoggedInUser(null) }}
					onSalonSelected={ handleSalonSelected }
				/>

				<Container style={{ padding: '20px' }}>
					<Routes>
						<Route
							path='/'
							element={(
								<CheckInsPage
									loggedInUser={loggedInUser}
									selectedSalon={selectedSalon}
									onLoginClicked={() => setShouldShowLogInDialog(true)}
									onSignUpClicked={() => setShouldShowSignUpDialog(true)}
								/>
							)}
						/>
						<Route
							path='/privacy'
							element={<PrivacyPage />}
						/>
						<Route
							path='/*'
							element={<NotFoundPage />}
						/>
					</Routes>
				</Container>

				{shouldShowSignUpDialog &&
					<SignupModal
						onDismiss={() => { setShouldShowSignUpDialog(false) }}
						onSignUpSuccessful={(user) => {
							setLoggedInUser(user);
							setShouldShowSignUpDialog(false)
						}}
					/>
				}
				{shouldShowLogInDialog &&
					<LoginModal
						onDismiss={() => { setShouldShowLogInDialog(false) }}
						onLoginSuccessful={(user) => {
							setLoggedInUser(user);
							setShouldShowLogInDialog(false)
						}}
					/>
				}
			</div>
		</BrowserRouter>
	);
}

export default App;
