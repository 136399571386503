import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Dropdown, ListGroup, Row, Stack } from "react-bootstrap";
import { CheckIn as CheckInModel } from '../models/checkin';
import * as CheckInApi from "../network/checkins_api"
import AddEditCheckInDialog from "./AddEditCheckInDialog";
import { DateTime } from "luxon";
import { SalonLocations } from "../constants/SalonLocations";

const UserDoCheckInPageLoggedInView = () => {

    const [currentCheckIn, setCurrentCheckIn] = useState<CheckInModel | null>(null);
    const [shouldShowCheckInDialog, setShouldShowCheckInDialog] = useState(false);
    const [checkInToEdit, setcheckInToEdit] = useState<CheckInModel | null>(null);
    const [waitTime, setWaitTime] = useState<number | 0>(0);

    async function getWaitTime() {
        let response = await CheckInApi.getWaitTime()
        setWaitTime(response?.waitTimeInMinutes ?? 0)
    }

    return (
        <>
            <Container>
                {shouldShowCheckInDialog &&
                    <AddEditCheckInDialog
                        onDismiss={() => setShouldShowCheckInDialog(false)}
                        onCheckInSaved={(newCheckin) => {
                            setShouldShowCheckInDialog(false);
                            setCurrentCheckIn(newCheckin)
                            getWaitTime()
                        }}
                    />
                }

                {checkInToEdit &&
                    <AddEditCheckInDialog
                        checkInToEdit={checkInToEdit}
                        onDismiss={() => setcheckInToEdit(null)}
                        onCheckInSaved={(updateCheckIn) => {
                            setcheckInToEdit(null)
                            setCurrentCheckIn(updateCheckIn)
                        }}
                    />
                }

                <>
                    {currentCheckIn !== null
                        //Display checked in view to the user
                        ? <UserCheckedInView checkIn={currentCheckIn} waitTime={waitTime} onEditClick={() => { }} />
                        : <UserNotCheckedInView onCheckInButtonClick={() => setShouldShowCheckInDialog(true)} />
                    }
                </>
            </Container>
        </>
    );
}

export default UserDoCheckInPageLoggedInView;

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
};

interface UserCheckedInViewProps {
    checkIn: CheckInModel,
    waitTime: number
    onEditClick: () => void
}

const UserCheckedInView = ({ checkIn, waitTime, onEditClick }: UserCheckedInViewProps) => {

    const [isHairCut, setIsHairCut] = useState<boolean>(false);

    useEffect(() => {
        if (checkIn.serviceType) {
            const lowercaseServiceType = checkIn.serviceType.toLowerCase(); // Convert to lowercase for case-insensitive comparison
            if (lowercaseServiceType.includes("haircut")) {
                setIsHairCut(true);
            } else {
                setIsHairCut(false);
            }
        }
    }, [checkIn.serviceType]);

    return (
        <>
            <Container>
                <Card className="checkedInDetailsCard">
                    <Card.Header as="h5" className="text-center">Your Check In Details</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>Service Type:</Col>
                            <Col sm={6}>{checkIn.serviceType}</Col>
                        </Row>
                        <Row>
                            <Col sm={6}>Service Provider:</Col>
                            <Col sm={6}>{checkIn.serviceProvider}</Col>
                        </Row>
                        <Row>
                            <Col sm={6}>Checked In At:</Col>
                            <Col sm={6}>{formatDate(checkIn.createdAt)}</Col>
                        </Row>
                        <Row>
                            <Col sm={6}>Status:</Col>
                            <Col sm={6} style={{ color: 'green', fontWeight: 'bold' }}>{checkIn.status}</Col>
                        </Row>

                        {isHairCut && (
                            <Row>
                                <Col sm={6}>Estimated wait time:</Col>
                                <Col sm={6} style={{ color: 'green', fontWeight: 'bold' }}>{formatWaitTime(waitTime)}</Col>
                            </Row>
                        )}
                    </Card.Body>
                    <Card.Footer>
                        {isHairCut ? (
                            <div>
                                The wait time indicated is an estimation based on the currently checked-in users and service type requested.
                                <br />
                                <br />
                                This is not an appointment. This will only ensure your spot in the line when you are physically present in the salon.
                            </div>
                        ) : (
                            <div>
                                This is not an appointment. This will only ensure your spot in the line when you are physically present in the salon.
                            </div>
                        )}
                    </Card.Footer>
                </Card>
                <p style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>Please head over to the salon located at:</p>
                <h5 style={{ display: 'flex', justifyContent: 'center' }}>{localStorage.getItem('selectedSalon') || '12990 National Rd SW, Etna, OH 43062'}</h5>
            </Container>
        </>
    )
}

function formatWaitTime(minutes: number): string {
    if (minutes < 60) {
        return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        const formattedHours = hours > 1 ? `${hours} hours` : '1 hour';
        const formattedMinutes = remainingMinutes > 0 ? `${remainingMinutes} minutes` : '';

        return `${formattedHours} ${formattedMinutes}`;
    }
}

//If user has checked in already, and logs out and logs back in, then we must show their check in.
// also card width is too wide in bigger size page.

interface UserNotCheckedInViewProps {
    onCheckInButtonClick: () => void
}

const UserNotCheckedInView = ({ onCheckInButtonClick }: UserNotCheckedInViewProps) => {

    const [checkInButtonDisabled, setCheckInButtonDisabled] = useState(true);
    const [isASalonChoosen, setIsASalonChoosen] = useState(false);
    const [choosenSalon, setChoosenSalon] = useState<string | null>(null)

    useEffect(() => {
        async function enableCheckIn() {
            try {
                // Create a DateTime object representing the current time in Eastern Time (ET)
                const currentLocalTime = DateTime.now().setZone('America/New_York');

                // Calculate the start time (9 AM) and end time (10 PM) in ET
                const startTime = currentLocalTime.set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
                const endTime = currentLocalTime.set({ hour: 21, minute: 0, second: 0, millisecond: 0 });

                // Get the current hour in ET
                const currentHour = currentLocalTime.hour;

                // Check if the current hour is between 9 AM and 10 PM in ET
                if (currentHour >= startTime.hour && currentHour <= endTime.hour && isASalonChoosen) {
                    setCheckInButtonDisabled(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
        enableCheckIn();
    }, [isASalonChoosen]);

    return (
        <>
            <Container>
                <Card className="mainPageCard">
                    <div className="centerElement">
                        <Card.Title>
                            <img
                                alt="Company logo"
                                src="/perfect-threading-one.png"
                                height="200"
                                className="d-inline-block align-top"
                            />
                        </Card.Title>
                        <Card.Body>
                            <h5>Experience Seamless Service with Perfect Threading</h5>
                            <p />
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Card>
                                        <Card.Body>Servies provided: Hair cut (Men & Women), Hair styling, Straightning, Facial, Body waxing, Eyebrow, Highlights etc.</Card.Body>
                                    </Card>
                                </ListGroup.Item>
                            </ListGroup>
                            <p />
                            {!checkInButtonDisabled &&
                                <h4 className="blinkText">Secure your spot in line by checking in.</h4>
                            }
                        </Card.Body>
                        <Card.Footer>
                            <Stack direction="vertical" gap={4}>
                                <Dropdown>
                                    {"Choose salon location: "}
                                    <Dropdown.Toggle variant="danger" id="dropdown-basic" size="lg">
                                        {choosenSalon ? choosenSalon : "Click to choose salon location"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {SalonLocations.map((location, index) => (
                                            <Dropdown.Item key={index}
                                                onClick={() => {
                                                    localStorage.setItem('selectedSalon', location);
                                                    setChoosenSalon(location)
                                                    setIsASalonChoosen(true)
                                                }
                                                }>{location}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Col>
                                    <Button disabled={checkInButtonDisabled} onClick={onCheckInButtonClick} variant="primary" size="lg">Check In</Button>
                                    {checkInButtonDisabled &&
                                        " Check In will be enabled only from 9am until 9pm"
                                    }
                                </Col>
                            </Stack>
                        </Card.Footer>
                    </div>
                </Card>
            </Container>
        </>
    )
}